export enum GridStrategy {
  CSS_TABLE_SINGLE, CSS_TABLE_DOUBLE/*, CSS_GRID*/
}

export function block(content: any, numColumns: number, rawHtml: boolean = false){
  return rawHtml ? {html: content, width: numColumns} : {content: content, width: numColumns}
}

export function blockCss(content: any, numColumns: number, cssClass: string, rawHtml: boolean = false){
  return rawHtml ? {html: content, width: numColumns, cssClass: cssClass} : {content: content, width: numColumns, cssClass: cssClass}
}

export function blocks(raw: boolean, cnt1: any, cnt2: any = null, cnt3: any=null, cnt4: any=null) {
  if (cnt4 != null)
    return [block(cnt1, 3, raw), block(cnt2, 3, raw), block(cnt3, 3, raw), block(cnt4, 3, raw)]

  if (cnt3 != null)
    return [block(cnt1, 4, raw), block(cnt2, 4, raw), block(cnt3, 4, raw)]

  if (cnt2 != null)
    return [block(cnt1, 6, raw), block(cnt2, 6, raw)]

  return [block(cnt1, 12, raw)]
}

// Can split the titles on separate grids objects, to have alignment issues with long titles;
// This requires a fixed amount of elements per row
export function splitTitle(data: any[], itemsPerRow: number, cssTitle: string, cssContent: string) {
  let titles = []
  let result = []

  data.forEach(d => {
    titles.push({content: d.title, width: d.width, cssClass: cssTitle ? cssTitle : d.cssClass});
    d.title = null;
    if (cssContent)
      d.cssClass = cssContent
  })

  for (let idxBatch = 0; idxBatch<data.length; idxBatch+=itemsPerRow) {
    let num = Math.min(data.length-idxBatch, itemsPerRow)

    // Titles
    for(let idx=idxBatch; idx<idxBatch+num; idx++)
      result.push(titles[idx])

    // Empty boxes, on the last title row
    for(let idxEmpty = num; idxEmpty<itemsPerRow; idxEmpty++)
      result.push({})

    // Data
    for(let idx=idxBatch; idx<idxBatch+num; idx++)
      result.push(data[idx])
  }

  return result
}
